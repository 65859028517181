export const PIC_TASK_APIS = {
  CREATE_PIC_TASK: "/api/pictask/create", // 创建生图任务
  GET_PIC_DETAIL: "/api/pictask/detail", // 获取生成图片详情
  GET_GALLERY: "/api/pictask/gallery", // 获取相册详情
  GET_GALLERY_CHARACTERS: "/api/pictask/gallery/characters", // 获取相册角色统计
  GET_CHARACTER_OPTIONS: "/api/character/options", // 获取创建角色配置
  GET_CHARACTER_OPTIONS_BRIEF: "/api/character/quick-options", // 获取快速创建角色配置
  CREATE_CHARACTER: "/api/character/ugc/create", // 创建角色
  GET_CHARACTER_CREATE_STATUS: "/api/character/ugc/status", // 角色创建状态
  GET_CHARACTERS: "/api/character/list", // 获取角色列表
  GET_DEFAULT_CHARACTER: "/api/pictask/character/recommend", // 获取默认角色
};

export default {
  ...PIC_TASK_APIS,
};
